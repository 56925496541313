body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Raleway", "Sans",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: rgb(249, 249, 249);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  backdrop-filter: 78;
  overflow: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.ck.ck-editor__main > .ck-editor__editable {
  border: 0px !important;
  box-shadow: none;
}

.ck.ck-editor__main > .ck-editor__editable.ck-focused {
  border: 0px !important;
  box-shadow: none;
}

.ck-toolbar {
  border: 0px !important;
}

#form-file-upload {
  text-align: center;
  position: relative;
  border-radius: 12px;
  background: #f8fafc;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 12px;
}

#label-file-upload.drag-active {
  background-color: #e9f2fb;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
